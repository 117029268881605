@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,800;1,400;1,500;1,700;1,800&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

body {
    font-size: 14px;
    line-height: 1.6;
    overflow-x: hidden;
    color: $color_blue;
    background: #F5F5F5;
}

.container-fluid {
    margin: 0 auto;
    min-width: 320px;
    max-width: 1440px;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
.w-100{
    width: 100%;
}

figure {
    margin: 0;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

.col-xs-margin {
    margin-bottom: 30px;
}

.row-margin-top {
    margin-top: 30px;
}

.wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    h1 {
        color: $color_blue;
        font-weight: 900;
        font-size: 30px;
        margin: 0 0 15px 0;
        text-transform: uppercase;
    }

    h4 {
        font-size: 16px;
    }

    h2, h3 {
        font-size: 18px;
        color: #666;
        font-weight: 700;
        margin: 0 0 15px 0;
    }

    h3 {
        color: $color_orange;
    }

    p {
        margin: 0 0 15px 0;
    }

    a {
        color: $color_orange;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        margin: 0 0 30px 0;
    }

    dl {
        dt, dd {
            text-align: left;
        }
    }

    aside {
        nav {
            padding-top: 15px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    padding: 0 15px 0 10px;
                    transition: all 0.3s;
                    background: $color_orange;
                    border-left: 5px solid transparent;

                    &:last-child {
                        a {
                            border: none;
                        }
                    }

                    &:hover,
                    &.selected {
                        border-color: $color_blue;
                    }

                    a {
                        display: block;
                        color: $color_blue;
                        text-decoration: none;
                        text-transform: uppercase;
                        border-bottom: 1px solid rgba(17, 25, 65, 0.15);
                        padding: 20px 0;
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .panel {
        .panel-heading {
            h2, h3 {
                margin-bottom: 0;
                font-weight: 500;
            }
        }
    }

    .dl-horizontal {
        //background: rgba(#f5f5f5, .8);
        display: flex;
        flex-wrap: wrap;
        border-radius: 5px;

        dt {
            flex: 0 0 40%;
        }
        dd {
            flex: 0 0 60%;
        }

        dt,
        dd {
            margin: 0;
            padding: 7.5px 10px;
            border-bottom: 1px solid #D9D9D9;

            &:last-of-type {
                border: none;
            }
        }
    }

    .file-preview {
        height: 292px;
        padding: 0;
        border: 1px dashed #E2E2E2;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;

        .file-drop-zone {
            border: none;
            margin: 0;
        }

        .file-preview-thumbnails {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            
            img {
                max-height: 280px; 
                max-width: 280px;
            }
        }
    }

    .checkbox {
        margin-top: 0;
        margin-bottom: 0;
        
        label {
            padding-left: 30px;
    
            input {
                margin-left: -30px;
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    .col-xs-margin {
        margin-bottom: 0px;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

    h1 {
        font-size: 40px;
    }

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .dl-horizontal.dl-horizontal-md {
        dt {
            width: 220px;
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}