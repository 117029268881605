.pagination {
  li {
    &:hover {
      a {
        background: $color_orange;
        border-color: $color_orange;
        color: $color_blue;
        transform: translateY(-3px);
        box-shadow: 0 5px 10px rgba(#000, .1);
      }
    }
    
    a {
      border-radius: 5px !important;
      margin: 0 5px;
      transition: all 300ms ease;
    }

    &.active {
      a {
        color: $color_blue;
      }
    }
  }
}