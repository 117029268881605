.card {
    padding: 25px;
    background: #fff;
    margin-bottom: 30px;

    h3 {
        color: $color_blue;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 16px;
    }
}
