.news {
    width: 100%;
    height: 40px;
    background: #fff;
    position: relative;
    border: solid 1px #ddd;
    overflow: hidden;
    margin-bottom: 20px;

    > .title {
        width: auto;
        height: 40px;
        display: inline-block;
        background: $color_orange; 
        position: relative;

        > h3 {
            display: inline-block;
            margin: 0;
            padding: 0 20px;
            line-height: 36px;
            color: #fff;
            height: 40px;
            box-sizing: border-box;
        }

        > span {
            width: 0;
            position: absolute;
            right: -10px;
            top: 10px;
            height: 0;
            border-style: solid;
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent $color_orange;
        }
    }

    > ul {
        padding: 0;
        margin: 0;
        list-style: none;
        position: absolute;
        left: 210px;
        top: 0;
        right: 40px;
        height: 40px;

        > li {
            position: absolute;
            height: 40px;
            width: 100%;
            line-height: 40px;
            display: none;

            > a {
                text-decoration: none;
                color: #333;
                overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: normal;

                > span {
                    color: #ddd;
                }

                &:hover {
                    color: $color_orange; 
                }
            }
        }
    }

    > .navigation {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 5px;
        top: 0;
        opacity: 0;
        
        > span {
            width: 20px;
            height: 40px;
            position: absolute;
            top: 0;
            cursor: pointer;
            opacity: 0.3;
            background-image: url(../img/arrows.png);
            background-repeat: no-repeat;

            &:hover {
                opacity: 1;
            }

            &:first-child {
                background-position: left center;
                left: 0;
            }

            &:last-child {
                background-position: right center;
                right: 0;
            }
        }
    }

    &:hover .navigation {
        opacity: 1;
    }

    > .navigation,
    > ul > li > a,
    > .navigation > span {
        transition: .25s linear;
        -moz-transition: .25s linear;
        -webkit-transition: .25s linear;
    }
}
