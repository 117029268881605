#search_wrapper {
    .well {
        box-shadow: none;
        margin-bottom: 0px;
        padding-bottom: 15px;

        form {
            position: relative;

            .form-group {
                margin-bottom: 0;
            }

            span.fa-search {
                position: absolute;
                right: 12px;
                top: 12px;
                font-size: 20px;
                background: none;
                border: 0;
                color: #999;
            }

            a.more {
                margin-top: 15px;
                line-height: 1;
                display: inline-block;
                color: #999;
                text-decoration: none;

                &:hover {
                    color: $color_orange;
                }

                i {
                    transition: all 0.5s;
                }

                &.open {
                    i {
                        transform: rotate(-180deg);
                    }
                }
            }

            #searchresults {
                display: none;
                position: absolute;
                left: 0;
                top: 46px;
                z-index: 1;
                width: 100%;

                .pending, .results {
                    display: none;
                }

                .list-group-item {
                    font-size: 16px;
                    font-weight: 600;
                    color: $gray;

                    i {
                        font-size: 24px;
                        margin-right: 10px;
                        color: $color_orange;
                    }
                }
            }

            #uitgebreid-zoeken {
                display: none;
                margin-top: 15px;
                border-top: 1px solid #CCC;
                padding-top: 15px;

                .form-group {
                    overflow: hidden;
                    margin-bottom: 15px;

                    label {
                        font-weight: normal;
                        color: #999;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {

    #search_wrapper {
        .well {
            form {
                #uitgebreid-zoeken {

                    .form-group {
                        //label {
                        //    float: left;
                        //    width: 25%;
                        //    padding-top: 5px;
                        //
                        //    + div {
                        //        float: left;
                        //        width: 75%;
                        //    }
                        //}
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {

}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {

    #search_wrapper {
        .well {
            form {
                #uitgebreid-zoeken {

                    .form-group {
                        width: 60%;
                    }
                }
            }
        }
    }
}